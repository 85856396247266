import { Grid, Paper } from "@mui/material";
import Steppers from "./Steppers";
import { useRef, useState } from 'react';
import Step0 from './Step0';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from "./Step3";
import Step4 from "./Step4";
// import Step5 from "./Step5";
// import Step6 from "./Step6";
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import emailjs from 'emailjs-com';
import { useNavigate } from "react-router-dom";


const styles = { root: { display: 'flex', alignItems: 'center', width: '100%', flexDirection: 'column', background: '#F5F6FD' } }
export default function MultiStepForm() {

    const theme = useTheme();
    const navigate = useNavigate()
    const matches_md = useMediaQuery(theme.breakpoints.down('md'))
    const matches_sm = useMediaQuery(theme.breakpoints.down('sm'))
    const [step, setStep] = useState(0);
    const [formData, setFormData] = useState({});
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [conformPassword, setConformPassword] = useState('');
    const [gender, setGender] = useState('')
    const [companyName, setCompanyName] = useState('');
    const [companyIndustry, setCompanyIndustry] = useState('');
    const [website, setWebsite] = useState('');
    const [businessDescription, setBusinessDescription] = useState('');
    const [designator, setDesignator] = useState('')
    const [members, setMembers] = useState([{ id: Date.now(), firstName: '', lastName: '', roleInCompany: '', phoneNumber: '', address: '', ssn_Number: '', errors: {} }]);
    const [selectedAddons, setSelectedAddons] = useState([]);
    const [totalAddonPrice, setTotalAddonPrice] = useState(0);
    const [loading, setLoading] = useState(false);

    const handleNext = (data) => {

        setFormData((prev) => ({ ...prev, ...data }));
        setStep((prev) => prev + 1);
    };

    const handlePrev = () => {
        setStep((prev) => prev - 1);
    };

    const handleSubmit = (data) => {
        setFormData((prev) => ({ ...prev, ...data }));
        let payload = { ...formData, ...data }
        setLoading(true)
        sendEmail(payload)
        // setStep(0)
    };


    const sendEmail = (formData) => {
        const { name, email, phone, gender, companyName, designator, companyIndustry, website, businessDescription, selectedAddons, ...members } = formData;

        const memberDetails = Object.keys(members)
            .filter(key => !isNaN(key))
            .map(key => {
                const member = members[key];
                return `Member #${parseInt(key) + 1}: 
                    - Name: ${member.firstName} ${member.lastName}
                    - Role in Company: ${member.roleInCompany}
                    - Phone Number: ${member.phoneNumber}
                    - Address: ${member.address}
                    - SSN: ${member.ssn_Number}\n`;
            }).join('\n');

        const addonsDetails = selectedAddons.map((addon, index) => `Addon #${index + 1}: ${addon}`).join('\n');

        const templateParams = {
            name,
            email,
            phone,
            gender,
            companyName,
            designator,
            companyIndustry,
            website,
            businessDescription,
            memberDetails,
            addonsDetails
        };

        emailjs.send(
            'service_sik985a',
            'template_516m7ja',
            templateParams,
            'm6-Zjo4I2uIKNv_mk'
        )
            .then((response) => {
                setLoading(false)
                navigate('/success', { state: { status: 'success' } })
            })
            .catch((error) => {
            });
    };

    return (
        <div style={{ padding: '0 6%' }} >
            <Grid container spcing={2} style={{ marginTop: '5%' }} gap={2}>
                <Grid item xs={12} md={3.3}>
                    <div style={{ padding: 20 }}>
                        <Steppers step={step} />
                    </div>

                </Grid>
                <Grid item xs={8}>
                    <div style={{ padding: matches_md ? 0 : 20 }}>
                        {step === 0 &&
                            <Step0 name={name} setName={setName} email={email} setEmail={setEmail} phone={phone} setPhone={setPhone} gender={gender} setGender={setGender} onNext={handleNext} />
                        }
                        {step === 1 && (
                            <Step1 companyName={companyName} setCompanyName={setCompanyName} companyIndustry={companyIndustry} setCompanyIndustry={setCompanyIndustry} website={website} setWebsite={setWebsite} businessDescription={businessDescription} setBusinessDescription={setBusinessDescription} designator={designator} setDesignator={setDesignator} onNext={handleNext} onPrev={handlePrev} />
                        )}
                        {step === 2 && (
                            <Step2 members={members} setMembers={setMembers} onPrev={handlePrev}
                                onNext={handleNext}
                            />
                        )}
                        {step === 3 && (
                            <Step3 selectedAddons={selectedAddons} setSelectedAddons={setSelectedAddons} totalAddonPrice={totalAddonPrice} setTotalAddonPrice={setTotalAddonPrice} onNext={handleNext} onPrev={handlePrev} />
                        )}
                        {step === 4 && (
                            <Step4 totalAddonPrice={totalAddonPrice} onSubmit={handleSubmit} onPrev={handlePrev} loading={loading} />
                        )}
                        {/* {step === 5 && (
                            <Step5 onSubmit={handleSubmit} onNext={handleNext} onPrev={handlePrev} />
                        )} */}
                        {/* {step === 6 && (
                            <Step6 onSubmit={handleSubmit} onPrev={handlePrev} />
                        )} */}
                    </div>
                </Grid>
            </Grid>
        </div>)
}
